<template>
<div class="resetpassword">
  <div class="signup-body">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="content col-md-6 col-lg-5 p-0">
          <div class="header">
            <h3 class="text-center">Reset your password</h3>
          </div>
          <hr>
          <div class="form pt-4 text-center">
            <p class="mb-4">Enter a new password for your {{email}} account.<br>
            </p>
            <el-alert v-show="passwordReset.success" class="mb-4 text-left" style="line-height: 1.4;"
                      :title=" 'Your password was successfully reset' "
                      type="success"
                      effect="dark"
                      :closable="true"
                      :show-icon="true">

            </el-alert>
            <el-alert v-show="passwordReset.error" class="mb-4 text-left" style="line-height: 1.4;"
                      :title="passwordReset.response"
                      type="error"
                      effect="dark"
                      :closable="true"
                      @close="passwordReset.error = false"
                      :show-icon="true">
            </el-alert>
            <el-form :label-position="labelPosition" label-width="140px" :model="resetPassword" :rules="rules" ref="resetPassword">
              <el-form-item label="New Password"  prop="password"  ref="password">
                <el-input type="password" v-model="resetPassword.password"  ></el-input>
              </el-form-item>
              <el-form-item label="Re-type Password"  prop="password_confirm" ref="password_confirm">
                <el-input type="password" v-model="resetPassword.password_confirm"></el-input>
              </el-form-item>
              <div v-show="showPasswordMustContain" class="password-strength">
                <span>Your password should contain the following:</span>
                <ul style="list-style-type: none; padding-left: 0; line-height: 1.5rem; font-size: 13px;">
                  <template  v-for="(contain, id) in passwordMustContain">
                    <li v-bind:key="id"
                        :id="id"
                        :class="[{ validated: !contain.error }, hasError ]"
                        v-html="contain.errorMsg">
                    </li>
                  </template>
                </ul>
              </div>
              <div class="text-center buttons pb-4">
                <el-button type="success"
                           @click="submit({
                             email: email,
                             password: resetPassword.password,
                             password_confirmation: resetPassword.password_confirm,
                             token: token,
                             memberType: memberType
                           })">Submit</el-button>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  import {mapActions, mapState} from "vuex";

  export default {
    data() {
      return {
        hasError: 'has_error',
        labelPosition: 'left',
        showPasswordMustContain: false,
        resetPassword: {
          password: '',
          password_confirm: '',
        },
        rules: {
          password: [
            {
              validator: (rule, value,callback) => {
                this.checkPasswordStrength(value)
                if (value) {
                  this.showPasswordMustContain = true
                } else {
                  this.showPasswordMustContain = false
                }

                if (value === this.resetPassword.password_confirm) {
                  this.$refs['password_confirm'].clearValidate()
                } else if (value !== this.resetPassword.password_confirm && this.resetPassword.password_confirm != '') {
                  callback(new Error('Password don\'t match'));
                } else {
                   callback();
                }
              },
              triggers: 'blur'
            },
            { required: true, message: 'Password field is required', trigger: 'blur' },

          ],
          password_confirm: [
            {
              validator: (rule, value, callback) => {
                if (value === '') {
                  callback(new Error('Please input the password again'));
                } 
                if (value === this.resetPassword.password) {
                  this.$refs['password'].clearValidate()
                }
                else if (value !== this.resetPassword.password) {
                  callback(new Error('Password don\'t match'));
                } else {
                  callback();
                }
              }
            }
          ],
        },
        passwordMustContain: {
          'has-lowecase-letter': {
            error: true,
            errorMsg: 'One or more lowercase letters'
          },
          'has-uppercase-letter': {
            error: true,
            errorMsg: 'One or more uppercase letters'
          },
          'has-number': {
            error: true,
            errorMsg: 'One or more numbers'
          },
          'min-8-character': {
            error: true,
            errorMsg: 'Minimum 8 characters'
          },
          'special-char': {
            error: true,
            errorMsg: 'Any special character is allowed'
          }
        },
      }
    },
    computed: {
      ...mapState(['passwordReset']),
      email() {
        return decodeURIComponent(this.$route.query.email)
      },
      token() {
        return this.$route.query.token
      },
      memberType() {
        return this.$route.query.memberType
      }

    },
    methods: {
      ...mapActions({ submit: 'resetPassword'}),
      checkPasswordStrength(password) {

        let error = false;

        this.passwordMustContain['has-lowecase-letter'].error = false;

        let lowercaseRegex = new RegExp("^(?=.*[a-z])");
        if (!lowercaseRegex.test(password)) {
          this.passwordMustContain['has-lowecase-letter'].error = true;
          error = true;
        }

        this.passwordMustContain['has-uppercase-letter'].error = false;
        let uppercaseRegex = new RegExp("^(?=.*[A-Z])");
        if (!uppercaseRegex.test(password)) {
          this.passwordMustContain['has-uppercase-letter'].error = true;
          error = true;
        }

        this.passwordMustContain['has-number'].error = false;
        let numberRegex = new RegExp("^(?=.*[0-9])");
        if (!numberRegex.test(password)) {
          this.passwordMustContain['has-number'].error = true;
          error = true;
        }

        this.passwordMustContain['min-8-character'].error = false;
        let longRegex = new RegExp("^(?=.{8,})");
        if (!longRegex.test(password)) {
          this.passwordMustContain['min-8-character'].error = true;
          error = true;
        }

        this.passwordMustContain['special-char'].error = false;
        let specialChar = new RegExp("^(?=.*[-!$%^&*()_+|~=`{}\\[\\]:\\/;<>?,.@#])");
        if (!specialChar.test(password)) {
          this.passwordMustContain['special-char'].error = true;
        }

        if (!password) {
          return false;
        }

        return error;
      },
    }
  }
</script>

<style lang="scss">
  .resetpassword {
    p {
      font-size: 16px;
      line-height: 1.6;
    }
  }
  .password-strength {
    padding-left:75px;
    border-radius: 4px;
    padding: 8px 16px;
    right: -338px;
    // background-color: #f0f9eb;
    top: 108px;
    text-align: left;
  }
  .password-strength span {
    color: #8e8f92;
    font-size: 13px;
  }
  .password-strength ul {
    list-style-type: none;
    padding-left: 0;
    line-height: 1.5rem;
    font-size: 13px;
  }
  .password-strength .has_error {
    color: red;
  }
  .password-strength .validated {
    color: green;
  }
  .password-strength li {
    padding-left: 18px;
    position: relative;
  }
  .password-strength li:before {
    content: "\e79d";
    display: table;
    font-family: 'element-icons' !important;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    left: 0;
    line-height: 1;
    position: absolute;
    speak: none;
    text-transform: none;
    top: 7px;
    vertical-align: baseline;
  }
  .password-strength li.validated:before {
    content: "\e79c";
  }
  #special-char.has_error {
    color: green;
  }
  #special-char.validated {
    color: green !important;
  }
  #special-char.has_error:before {
    content: "\e79c";
  }
</style>
